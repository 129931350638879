"use client"

import ButtonContainer from '@/components/button-container';
import * as styles from './styles.css';
import Button from '@/components/button';
import Image from 'next/image';
import { Dela_Gothic_One } from "next/font/google";
import { Albert_Sans } from "next/font/google";

const delaGothicOne = Dela_Gothic_One({
  weight: "400",
  subsets: ["latin"],
  variable: "--font-dela-gothic-one",
});

const albertSans = Albert_Sans({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
  variable: "--font-albert-sans",
});

export default function GlobalError({
  error,
  // reset,
}: {
  error: Error & { digest?: string }
  // reset: () => void
}) {
  console.log(error);
  return (
    <html>
      <head>
         <title>Australian Reptile Park | Home | Visit our website to find out more</title>
         <meta name="description" content="Australia's BEST Tourist Attraction! Awarded Gold for 'Major Tourist Attraction' at the 2023 Australian Tourism Awards. The Australian Reptile Park is Australia’s original hands-on zoo. Home to over 2,000 animals and located"></meta>
      </head>
      <body className={`${delaGothicOne.variable} ${albertSans.variable}`}>
        <main>
          <div className={styles.layout} id="main-content">
            <div className={styles.error}>
              <div className={styles.notFoundImage}>
                <div className={styles.notFoundGraphic}>
                  <picture className={styles.pictureContainer}>
                    <source media="(max-width: 1023px)" srcSet="/images/not-found-graphic-mobile.png" />

                    <Image
                      src="/images/not-found-graphic.png"
                      alt="not found"
                      placeholder="blur"
                      width={1600}
                      height={196}
                      blurDataURL="/images/not-found-graphic.png"
                      quality={80}
                    />
                  </picture>
                </div>
                <Image
                  src="/images/500.jpeg"
                  alt="not found"
                  fill={true}
                  style={{ objectFit: "cover" }}
                  placeholder="blur"
                  priority={true}
                  blurDataURL="/images/500.jpeg"
                  quality={80}
                />
              </div>
              <div className={styles.notFoundTextContainer}>
                <div>
                  <div className={styles.notFoundSubHeading}>Error 500 </div>
                  <h1 className={styles.notFoundHeading}>We’ve hit a teething issue</h1>
                  <p className={styles.notFoundText}>We’re having problems processing your request. Sorry about that!</p>
                  <ButtonContainer spacing="mediumMobile" layout="align-left">
                    <Button
                      label="Back to Homepage"
                      style="primary"
                      size="large"
                      href='/'
                      linkType='internal link'
                    />
                  </ButtonContainer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </body>
    </html>
  )
}
