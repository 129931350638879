import * as styles from "./styles.css";

interface ButtonContainerProps {
  children: React.ReactNode;
  layout?: "full-width-mobile" | "align-left" | "default" | "align-left-mobile";
  spacing?: "default" | "large" | "mediumMobile";
}

export default function ButtonContainer(props: ButtonContainerProps) {
  return (
    <div className={styles.container({ layout: props.layout, spacing: props.spacing ?? 'default' })}>
      {props.children}
    </div>
  );
}
